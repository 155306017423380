import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DocumentMeta from "react-document-meta";
import { faTwitter, faFacebook, faInstagram, faTiktok, faSnapchatGhost } from "@fortawesome/free-brands-svg-icons";
import Post from "./Post";

const Home = () => {
	const { site } = useParams();
	const [isLoading, setIsLoading] = React.useState(true);
	const [siteInfo, setSiteInfo] = React.useState({});
	const [posts, setPosts] = React.useState([]);

	React.useEffect(() => {
		fetchSiteDetail(site);
	}, [site]);

	React.useEffect(() => {
		fetchPosts(siteInfo?.id);
	}, [siteInfo?.id]);

	const setBackgroundColour = async (backgroundColour) => {
		document.body.style.backgroundColor = backgroundColour;
	};

	const fetchSiteDetail = async (site) => {
		const sitesData = await axios({
			method: "GET",
			url: `https://sociallinks-cms.rt-itservices.co.uk/companies?slug_eq=${site}`,
		});
		if (sitesData.data.length > 0) {
			setSiteInfo(sitesData.data[0]);
			sitesData.data[0].backgroundColour && setBackgroundColour(sitesData.data[0].backgroundColour);
			setIsLoading(false);
		}
	};

	const meta = {
		title: siteInfo?.name + " | Social Links",
		description: `All the latest social posts from ${siteInfo?.name} in one handy place`,
		meta: {
			charset: "utf-8",
			name: {
				keywords: siteInfo?.name + ", social, instagram, facebook, twitter, links",
			},
		},
	};

	const fetchPosts = async (siteId) => {
		const posts = await axios({
			method: "GET",
			url: `https://sociallinks-cms.rt-itservices.co.uk/posts?company=${siteId}&_sort=id:DESC&_limit=-1`,
		});
		if (posts.data.length >= 0) {
			setPosts(posts.data);
		}
	};

	return isLoading ? (
		<div className="flex content-center justify-center overflow-hidden text-gray-900">
			<div className="flex flex-col content-center justify-center bg-white shadow-md rounded w-full md:w-2/3 lg:w-2/3 xl:w-2/3 m-8 pb-8 pt-8">
				<div className="header flex flex-col content-center text-center ">
					<h1 className="text-4xl font-extrabold pt-5">Loading...</h1>
				</div>
			</div>
		</div>
	) : (
		<DocumentMeta {...meta}>
			<div className="flex content-center justify-center overflow-hidden text-gray-900">
				<div className="flex flex-col content-center justify-center bg-white shadow-md rounded w-full md:w-2/3 lg:w-2/3 xl:w-2/3 m-8 pb-8 pt-8">
					<div className="header flex flex-col content-center text-center ">
						<a href={siteInfo.defaultURL} target="_blank" rel="noopener noreferrer" style={{ color: siteInfo.highlightColour }}>
							<img
								className="h-16 w-16 rounded-full mx-auto"
								src={siteInfo.logo ? "https://sociallinks-cms.rt-itservices.co.uk" + siteInfo.logo.formats.medium.url : null}
								alt={siteInfo.name + " logo"}
								style={{ width: "150px", height: "150px" }}
							/>
						</a>
						<h1 className="text-4xl font-extrabold pt-5">{siteInfo?.name}</h1>
						<h3 className="text-sm">
							<a href={siteInfo.defaultURL} target="_blank" rel="noopener noreferrer" style={{ color: siteInfo.highlightColour }}>
								{siteInfo.defaultURL?.replace("https://", "").replace("http://", "")}
							</a>
						</h3>
						<div className="socials flex flex-row justify-center pt-5">
							{siteInfo.facebook && (
								<a
									href={siteInfo.facebook}
									target="_blank"
									rel="noopener noreferrer"
									className="pr-1 pl-1 text-gray-600"
									style={{ color: siteInfo.highlightColour }}
								>
									<FontAwesomeIcon icon={faFacebook} size="2x" />
								</a>
							)}
							{siteInfo.insta && (
								<a
									href={siteInfo.insta}
									target="_blank"
									rel="noopener noreferrer"
									className="pr-1 pl-1 text-gray-600"
									style={{ color: siteInfo.highlightColour }}
								>
									<FontAwesomeIcon icon={faInstagram} size="2x" />
								</a>
							)}

							{siteInfo.twitter && (
								<a
									href={siteInfo.twitter}
									target="_blank"
									rel="noopener noreferrer"
									className="pr-1 pl-1 text-gray-600"
									style={{ color: siteInfo.highlightColour }}
								>
									<FontAwesomeIcon icon={faTwitter} size="2x" />
								</a>
							)}
							{siteInfo.snapchat && (
								<a
									href={siteInfo.snapchat}
									target="_blank"
									rel="noopener noreferrer"
									className="pr-1 pl-1 text-gray-600"
									style={{ color: siteInfo.highlightColour }}
								>
									<FontAwesomeIcon icon={faSnapchatGhost} size="2x" className="" />
								</a>
							)}
							{siteInfo.tiktok && (
								<a
									href={siteInfo.tiktok}
									target="_blank"
									rel="noopener noreferrer"
									className="pr-1 pl-1 text-gray-600"
									style={{ color: siteInfo.highlightColour }}
								>
									<FontAwesomeIcon icon={faTiktok} size="2x" className="" />
								</a>
							)}
						</div>
					</div>
					<div className="posts flex flex-col content-center justify-center md:flex-row lg:flex-row xl:flex-row flex-wrap p-8">
						{posts?.map((post) => (
							<Post key={post.id} title={post.title} image={"https://sociallinks-cms.rt-itservices.co.uk" + post.image?.url} link={post.url} />
						))}
					</div>
				</div>
			</div>
		</DocumentMeta>
	);
};

export default Home;
