import React from "react";

const Post = ({ image, title, link }) => {
	return (
		<>
			<div className="post xl:w-1/3 lg:w-1/3 p-1 pb-8 flex justify-center">
				<a
					href={link + "?utm_source=sclnk&utm_medium=social"}
					target="_blank"
					rel="noopener noreferrer"
					className="flex flex-col align-center"
					style={{ maxWidth: "300px" }}
				>
					<img src={image} alt={title} width="300px" height="300px" />
					<h3 className="text-center pt-3">{title}</h3>
				</a>
			</div>
			<hr className="pb-8" />
		</>
	);
};

export default Post;
